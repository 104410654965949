/**
 * File: globals.tsx
 * Desc: Holds global declarations for the app
 */
import React, { useEffect } from 'react'
import axios, { AxiosResponse } from 'axios'
import LoadingButton from '@mui/lab/LoadingButton'
import Button from '@mui/material/Button'
import BaseTooltip from './globalComps/BaseTooltip'
import tableToPDF from './tableToPDF'

const ApiUrl: string = 'https://user-testing.2.rotoedgepro.com/api'
const WSUrl: string = ApiUrl.replace(/^http/, 'ws').replace(/\/api$/, '/ws')
// set axios base url
const api = axios.create({baseURL: process.env.REACT_APP_BASE_URL || ApiUrl})
const TimeFormat: string = 'hh:mm a'  // moment time format
const DateFormat: string = 'MMM Do, YY'  // moment date format
const DateFormatBackend: string = 'MMM D, YY'  // moment date format
const DateTimeFormat: string = `hh:mm a, MMM dd, yy`
const DateFNSFormat: string = 'MMM do, yy'
const FormSpacing: number = 2
const EnterKey: number = 13

const SECONDARY_LP: number = 1
const FOAMING_LP: number = 2
const ASSEMBLY_LP: number = 3
const SHIPPING_LP: number = 4

// close form helper func
const closeForm = (submit: boolean, openFunc: (open: boolean) => void, loadFunc: (load: boolean) => void,
                   listFunc: (list: any[]) => void, url: string, respDataName: string | undefined) => {
  openFunc(false)

  if (submit) {
    loadFunc(true)
    api.get(url)
      .then((resp: AxiosResponse) => {
        if (respDataName)
          listFunc(resp.data[respDataName])
        else
          listFunc(resp.data)
        loadFunc(false)
      })
  }
}

const refreshObjects = (updatedData: any, create: boolean, data: any[], cb?: () => void) => {
  if (!create) {
    return data.map((object: any) => {
      if (object.id === updatedData.id) {
        updatedData['highlight'] = object['highlight']
        updatedData['tooltip'] = object['tooltip']
        updatedData['id'] = object.id
        if (cb) cb()
        return updatedData
      } else {
        if (cb) cb()
        return object
      }
    })
  } else {
    const newData = [...data]
    newData.push(updatedData)
    if (cb) cb()
    return newData
  }
}

const capitialize = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

/**
 * Global button func TODO: Move base component
 * @param act {loadingButton: boolean, loading: boolean, outlined: boolean, action: () => void, text: string,
 * tooltip: boolean, left: boolean, disabled: boolean}: action
 * @param key: number
 */
const button = (act: any, key: number) => {

	let sx: any;
	if (act.left) sx = act.left ? { margin: '2px', marginRight: 'auto' } : { margin: '2px' };
	else if (act.right) sx = act.right ? { margin: '2px' } : { margin: '2px' };
	else sx = {};

	const handleClick = () => {
		if (act.action) act.action(); // Call the original action
	}

	let btn: any;
	if (act.loadingButton) {
		btn =
			<span>
				<LoadingButton
					type={act.submit ? 'submit' : 'button'}
					onClick={handleClick}
					loading={act.loading}
					variant={act.outlined ? 'outlined' : 'contained'}
					startIcon={act.icon}
					sx={sx}
					color='primary'
					autoFocus={act.autoFocus}
					disabled={act.disabled}
				>
					{act.text}
				</LoadingButton>
			</span>
	} else {
		btn =
			<Button
				type={act.submit ? 'submit' : 'button'}
				key={key}
				variant={act.outlined ? 'outlined' : 'contained'}
				color={act.color ? act.color : 'primary'}
				startIcon={act.icon}
				onClick={handleClick}
				sx={sx}
				href={act.url ? act.url : undefined}
				target={act.url ? '_blank' : undefined} // Add target attribute for external links
				disabled={act.disabled}
			>
				{act.text}
			</Button>
	}

	if (act.tooltip && act.tooltip !== '')
		return (
			<BaseTooltip text={act.tooltip} key={key}>
				{btn}
			</BaseTooltip>
		)
	else
		return btn;
}

const getAccordionBackgroundColor = (theme: any, light?: boolean) => {
  if (theme.palette.mode === 'dark')
    if (light)
      return '#1a1a1a'
    else
      return '#282828'
  else
    if (light)
      return '#ffffff'
    else
      return '#ececec'
}

/**
 * useEffect api wrapper, adds the isactive flag for api calls in use effect
 * @param effect original use effect function
 * @param deps original use effect dependencies
 */
const useEffectApi = (effect: () => void, deps: any[]) => {
  useEffect(() => {
    let isActive = true

    if (isActive)
      effect()

    return () => {isActive = false}
  }, deps)
}

/**
 * used to auto highlight text in a text input field (for easy replacement)
 * @param element
 */
const highlightTextInElement = (element: HTMLElement) => {
  // console.log('element :', element)
  let range: Range = document.createRange()
  // console.log('range :', range)
  range.selectNodeContents(element)
  const selection: Selection | null = window.getSelection()
  // console.log('selection :', selection)

  if (selection) {
    selection.removeAllRanges()
    selection.addRange(range)
  }
}

// return an array of date objects for start (monday)
// and end (sunday) of week based on supplied
// date object or current date
const startAndEndOfWeek = (date?: Date | undefined) => {

  // If no date object supplied, use current date
  // Copy date so don't modify supplied date
  const now = date? new Date(date) : new Date()

  // set time to some convenient value
  now.setHours(0,0,0,0)

  // Get the previous Monday
  const monday = new Date(now)
  monday.setDate(monday.getDate() - monday.getDay() + 1)

  // Get next Sunday
  const sunday = new Date(now)
  sunday.setDate(sunday.getDate() - sunday.getDay() + 7)

  // Return array of date objects
  return [monday, sunday]
}

const startAndEndOfMonth = (date?: Date | undefined) => {
  // see above for documentation
  const now = date? new Date(date) : new Date()
  const first = new Date(now.getFullYear(), now.getMonth(), 1)
  const last = new Date(now.getFullYear(), now.getMonth() + 1, 0)

  return [first, last]
}

const generateBomPdf = (objects: any[]) => {
  const headers: any[] = [
    {text: 'Item', width: 50},
    {text: 'Description', width: 165},
    {text: 'Type', width: 150},
    {text: 'Quantity', width: 75},
    {text: 'Bin Location', width: 95},
    {text: 'Include in MRP', width: 95},
    {text: 'First Article', width: 95}
  ]

  const firstItem = objects[0]
  const rows: string[][] = [[
    firstItem.parent_item, firstItem.parent_description, firstItem.parent_item_type_name, '-', '-', '-', '-'
  ]]

  for (const item of objects)
    rows.push([
      item.child_item, item.child_description, item.type_name, item.quantity, item.bin_location,
      !item.ignore_in_mrp, item.first_article
    ])

  const pdf: any = new tableToPDF(`Bill of Materials: ${firstItem.parent_item}`, headers, rows, 8,
    true)
  pdf.generatePDF()
}

const formatCurrency = (amount: number) => {
  return amount.toLocaleString('en-EN', {style: 'currency', currency: 'USD'})
  // let currencyString: string = amount.toString().replace('.', '')
  //
  // let firstHalf = currencyString.substring(0, currencyString.length - 2)
  // let secondHalf = currencyString.substring(currencyString.length - 2, currencyString.length)
  // return parseFloat(`${firstHalf}.${secondHalf}`).toLocaleString('en-EN', {style: 'currency', currency: 'USD'})
}

export {
  api, ApiUrl, WSUrl, TimeFormat, DateFormat, DateTimeFormat, DateFormatBackend, DateFNSFormat, FormSpacing, EnterKey,
  closeForm, refreshObjects, button, capitialize, getAccordionBackgroundColor, useEffectApi, highlightTextInElement,
  startAndEndOfWeek, startAndEndOfMonth, generateBomPdf, SECONDARY_LP, FOAMING_LP, ASSEMBLY_LP, SHIPPING_LP,
  formatCurrency
}
